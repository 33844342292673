<template>
    <div class="Methods full-height pa-4 d-flex flex-column ">
        <div class="d-flex full-height-footerButtons">
            <div class="content-area pa-8 pt-5 flex-grow-1 bg-white ">
                <v-row class="d-flex align-center mb-5 filter">
                    <v-col cols="5" class="pl-0 d-flex align-center">
                        <p>{{ $t('general.status') }}:</p>
                        <v-select :items="filterStatus" outlined hide-details="auto" v-model="filterStatusSelected"
                                  attach class="ml-6"/>
                    </v-col>
                </v-row>

                <v-data-table
                    disable-pagination
                    hide-default-footer
                    :headers="methodsTableHeaders"
                    :items="filteredList"
                >
                    <template v-slot:body="{ items }">
                        <tbody>
                        <tr v-for="(item) in items" :key="item.id" @click="editRow(item)">
                            <td>{{ item.methodName }}</td>
                            <td>{{ findStatus(item.status) }}</td>
                            <td :class="{edit: item.editable && checkUserRights('methodsEdit'), show: !item.editable || !checkUserRights('methodsEdit')}"></td>
                        </tr>
                        </tbody>
                    </template>
                </v-data-table>
            </div>
        </div>
        <v-bottom-navigation grow dark background-color="disiBluegreyLight" class="pt-4" height="76">
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button buttonFunction="printAll" :successCheck="showPrintSuccess" :progress="showPrintProgress"
                           @footerButtonClick="printAll"
                           :class="{'disabled': !checkUserRights('methodsPrint')}"></footer-button>
            <footer-button buttonFunction="new" @footerButtonClick="newMethod"
                           :class="{'disabled': !checkUserRights('methodsEdit')}"></footer-button>
        </v-bottom-navigation>
    </div>
</template>

<script>
import FooterButton from '@/components/FooterButton.vue'
import {mapState} from "vuex";
import mixins from "@/mixins/mixins";

export default {
    name: 'Methods',
    components: {
        FooterButton,
    },
    props: {},
    data() {
        return {
            filterStatusSelected: 'show all',
            showPrintProgress: false,
            showPrintSuccess: false,
        }
    },
    computed: {
        ...mapState([
            'methodlist',
            'medialist'
        ]),
        methodsTableHeaders() {
            return [
                {text: this.$t('general.name'), align: 'start', value: 'methodName'},
                {text: this.$t('general.status'), value: 'status'},
                {text: "", sortable: false,}
            ]
        },
        filterStatus() {
            return [
                this.$t('filter.showAll'),
                this.$t('filter.showActive'),
                this.$t('filter.showInactive'),
            ]
        },
        filteredList() {
            let filteredlist = this.$store.state.methodlist;
            if (this.filterStatusSelected == this.$t('filter.showActive')) {
                filteredlist = filteredlist.filter(function (method) {
                    return method.status == true;
                })
            } else if (this.filterStatusSelected == this.$t('filter.showInactive')) {
                filteredlist = filteredlist.filter(function (method) {
                    return method.status == false;
                })
            }
            return filteredlist;
        }
    },
    methods: {
        findStatus(statusValue) {
            let status = this.$t('general.active')
            if (statusValue === false) {
                status = this.$t('general.inactive')
            }
            return status;
        },
        editRow(method) {
            this.$store.commit('ChangeEditMethod', method);
        },
        printAll() {
            this.showPrintProgress = true;
            this.$store.dispatch('getAxiosNoSetter', '/disi/methods/printall')
                .then(response => {
                    this.showPrintProgress = false;
                    if (response.status === 200) {
                        this.showPrintSuccess = true;
                        setTimeout(() => {
                            this.showPrintSuccess = false;
                        }, 800)
                    }
                });
        },
        newMethod() {
            let method = {
                id: null,
                methodName: "",
                testingTime: 0,
                maxTestingTimeStatus: false,
                maxTestingTime: 0,
                editable: true,
                media: 0,
                basket: 0,
                combinationTest: false,
                threshold: 0.3,
                temperatureStatus: true,
                temperatureMin: 35,
                temperatureMax: 39,
                pretestStatus: false,
                pretestMedia: 0,
                pretestTestingTime: 0,
                assessmentStatus: false,
                assessmentMinDisintegrated: 0,
                assessmentMaxDisintegrated: 0,
                fastDisintegration: false,
                withoutDisc: false,
                manual: false,
                status: true
            }
            this.$store.commit('ChangeEditMethod', method);
        },
    },
    mixins: [mixins],
    created() {
        this.$store.dispatch('getAxiosSetter', ['/disi/media/list', 'medialist']);
        this.$store.dispatch('getAxiosSetter', ['/disi/methods/list', 'methodlist']);
    },
}
</script>
<style scoped lang="scss">
</style>